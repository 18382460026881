import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { config, animated, useSpring } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'
import { Button } from '../elements'

type PageProps = {
  data: {
    projects: {
      nodes: {
        title: string
        category: string
        color: string
        slug: string
        cover: ChildImageSharp
      }[]
    }
  }
}

const ProjectImg = styled(Img)`
  filter: brightness(75%); 
  -webkit-filter: brightness(75%); 
  :hover {
    filter: brightness(95%); 
    -webkit-filter: brightness(95%); 
  }
`

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 35vh;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 60vw;
  }
`

const PButton = styled.button`
    cursor: pointer;
    display: inline-block;
    background-color: white;
    position: absolute; 
    z-index: 1;
    top: 15px;
    right: 15px;
    width: auto;
    margin: auto;
    text-align: right;
    font-weight: 600;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-opacity: 80%;
    font-size: ${props => props.theme.fontSizes[2]};
    padding: ${props => props.theme.space[3]};

    @media (max-width: ${props => props.theme.breakpoints[3]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      padding: ${props => props.theme.space[2]};
    }
`

const Projects: React.FunctionComponent<PageProps> = ({ data: { projects } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#000">
      <SEO title="Projects | Conrad" />
      <Area style={pageAnimation}>
        {projects.nodes.map(project => (
          <GridItem key={project.slug} to={project.slug} aria-label={`View project "${project.title}"`}>
            <ProjectImg fluid={project.cover.childImageSharp.fluid} />
            <PButton color={project.color}>{project.title}</PButton>
            {/* <PCategory>{project.category}</PCategory> */}
          </GridItem>
        ))}
      </Area>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query Projects {
    projects: allProjectsYaml {
      nodes {
        title
        category
        slug
        color
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
